import { AddLink, MoreVert, Refresh, List as ListIcon } from '@mui/icons-material';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Popper
} from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SET_ROWS_TO_MATCH, useMainDispatch } from "../../contexts/MainContext";
import { usePrimusState } from "../../contexts/PrimusContext";
import { PrimusApi } from "../../services/PrimusApi";

export const DetailPanelActionMenu = ({ row, refreshRow, dispatchError, setUsages, loading, setLoading }) => {
  const { t } = useTranslation('translations');
  const { baseUrl } = usePrimusState();
  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef(null);

  const mainDispatch = useMainDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);



  const matchRow = (noFuzzy) => {
    setLoading(true);
    mainDispatchCallback({
      type: SET_ROWS_TO_MATCH,
      rowsToMatch: { [row.id]: true },
      noFuzzy: noFuzzy
    });
    closeMenu();
  };

  const closeMenu = () => {
    setLoading(false);
    setOpenMenu(false);
  }

  const onRefreshRow = (updateUsages) => {
    setLoading(true);
    let api = new PrimusApi(baseUrl);
    api.getArtifact(row.id).then((res) => {
      api.getUsage([row.id]).then((usageRes) => {
        let usageNr = 0;
        if (usageRes[row.id]) {
          usageNr = usageRes[row.id].length;
          if (updateUsages) setUsages(usageRes[row.id]);
        }
        refreshRow(res, usageNr);
        closeMenu();
      }).catch((e) => {
        dispatchError('table.default.jobs.errorGettingUsage', e);
        closeMenu();
      });
    }).catch((e) => {
      dispatchError('table.default.jobs.getArtifactError', e, { name: row.name });
      closeMenu()
    });
  };

  return (
    <>
      <IconButton
        onClick={() => setOpenMenu(!openMenu)}
        color='primary'
        sx={{ border: '1px solid lightgray',  }}
        ref={anchorRef}
      >
        <MoreVert />
      </IconButton>

      <Popper anchorEl={anchorRef.current} open={openMenu} placement='bottom-start' style={{ zIndex: 1250 }} transition>
        {({ TransitionProps }) => (
          <Grow { ...TransitionProps }>
            <Paper>
              <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                <List>
                  <ListItemButton disabled={loading} onClick={() => onRefreshRow(false)}>
                    <ListItemIcon>
                      <Refresh />
                    </ListItemIcon>
                    <ListItemText primary={t('table.default.detailpane.actions.refresh')} />
                  </ListItemButton>

                  <ListItemButton disabled={loading} onClick={() => onRefreshRow(true)}>
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('table.default.detailpane.actions.listUsages')} />
                  </ListItemButton>

                  {!row.authorityId && (
                    <>
                      <ListSubheader sx={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                          <AddLink/>
                        </ListItemIcon>
                        {t('table.default.detailpane.actions.match')}
                      </ListSubheader>
                      <ListItemButton
                        disabled={loading}
                        onClick={() => matchRow(false)}
                        sx={{ padding: '4px 16px 4px 80px' }}
                      >
                        <ListItemText primary={t('table.default.detailpane.actions.matchFuzzy')}/>
                      </ListItemButton>
                      <ListItemButton
                        disabled={loading}
                        onClick={() => matchRow(true)}
                        sx={{ padding: '4px 16px 4px 80px' }}
                      >
                        <ListItemText primary={t('table.default.detailpane.actions.matchNoFuzzy')}/>
                      </ListItemButton>
                    </>
                  )}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}