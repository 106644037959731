import '@fontsource/roboto';
import { Dehaze, FiberSmartRecord } from '@mui/icons-material';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { MENU_CLOSED, useAppDispatch } from '../../app/AppContext';
import { SET_USER_LNG, useAuthsDispatch, useAuthsState } from "../../app/auths/AuthsContext";
import { SET_HELP_MENU_CONTENT_URL, useMainDispatch, VIEW_CHANGED } from '../../contexts/MainContext';
import { usePrimusState } from '../../contexts/PrimusContext';
import { SET_TABLE_PINNED_ROWS, useTableDispatch } from '../../contexts/TableContext';
import { TvattHelpButton } from '../TvattHelpMenu/TvattHelpButton';
import { FilterList } from './FilterList';

export const TvattMenuOptions = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const mainDispatch = useMainDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);
  const tableDispatch = useTableDispatch();
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);
  const authsDispatch = useAuthsDispatch();
  const authsDispatchCallback = useCallback(authsDispatch, [authsDispatch]);
  const navigate = useNavigate();

  const { versionMajor, versionMinor } = usePrimusState();
  const { userLng } = useAuthsState()
  const { t } = useTranslation('translations');

  const handleNavigation = (path) => {
    dispatch({ type: MENU_CLOSED });

    tableDispatchCallback({
      type: SET_TABLE_PINNED_ROWS,
      pinnedRows: {
        top: [],
        bottom: []
      }
    });

    mainDispatchCallback({
        type: VIEW_CHANGED
    });

    navigate(path);
  }

  const handleChangeLng = (_, v) => {
    if (v) {
      console.log(v)
      authsDispatchCallback({
        type: SET_USER_LNG,
        userLng: v
      })
    }
  }

  return (
    <>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleNavigation('/')}
            selected={location.pathname === '/'}
            sx={{
              padding: 2,
              width: '100%'
            }}
          >
            <ListItemIcon>
              <Dehaze color={location.pathname === '/' ? 'primary' : undefined} />
            </ListItemIcon>
            <ListItemText primary={t('menu.washer.list')} />
          </ListItemButton>
        </ListItem>
        <FilterList />
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleNavigation('/duplicate')}
            selected={location.pathname === '/duplicate'}
            sx={{
              padding: 2,
              width: '100%'
            }}
          >
            <ListItemIcon>
              <FiberSmartRecord color={location.pathname === '/duplicate' ? 'primary' : undefined} />
            </ListItemIcon>
            <ListItemText primary={t('menu.washer.grouped')} />
          </ListItemButton>
        </ListItem>
      </List>
      <Box sx={{ alignItems: 'flex-end', display: 'flex', flexGrow: 1, justifyContent: 'center', paddingBottom: 0.5 }}>
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column',  width: '100%' }}>
          <ToggleButtonGroup
            value={userLng}
            exclusive
            onChange={handleChangeLng}
            size='small'
            sx={{ marginBottom: '8px' }}
          >
            <ToggleButton value='no'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='37.547 85.333 341.333 341.333' height='24px' width='24px'>
                <path fill='#D80027' d='M0 85.334h512v341.337H0z' />
                <path fill='#FFF' d='M512 295.883H202.195v130.783h-79.76V295.883H0v-79.772h122.435V85.329h79.76v130.782H512v61.218z' />
                <path fill='#2E52B2' d='M512 234.666v42.663H183.652v149.337h-42.674V277.329H0v-42.663h140.978V85.329h42.674v149.337z' />
              </svg>
            </ToggleButton>
            <ToggleButton value='sv'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='30.72 85.333 341.333 341.333' height='24px' width='24px'>
                <path fill='#0052B4' d='M0 85.333h512V426.67H0z' />
                <path fill='#FFDA44' d='M192 85.33h-64v138.666H0v64h128v138.666h64V287.996h320v-64H192z' />
              </svg>
            </ToggleButton>
            <ToggleButton value='en'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='85.5 0 342 342' height='24px' width='24px'>
                <g fill='#FFF'>
                  <path d='M0 0h513v341.3H0V0z' />
                  <path d='M311.7 230 513 341.3v-31.5L369.3 230h-57.6zM200.3 111.3 0 0v31.5l143.7 79.8h56.6z' />
                </g>
                <path d='M393.8 230 513 295.7V230H393.8zm-82.1 0L513 341.3v-31.5L369.3 230h-57.6zm146.9 111.3-147-81.7v81.7h147zM90.3 230 0 280.2V230h90.3zm110 14.2v97.2H25.5l174.8-97.2zm-82.1-132.9L0 45.6v65.7h118.2zm82.1 0L0 0v31.5l143.7 79.8h56.6zM53.4 0l147 81.7V0h-147zm368.3 111.3L513 61.1v50.2h-91.3zm-110-14.2V0h174.9L311.7 97.1z' fill='#0052B4' />
                <g fill='#D80027'>
                  <path d='M288 0h-64v138.7H0v64h224v138.7h64V202.7h224v-64H288V0z' />
                  <path d='M311.7 230 513 341.3v-31.5L369.3 230h-57.6zm-168 0L0 309.9v31.5L200.3 230h-56.6zm56.6-118.7L0 0v31.5l143.7 79.8h56.6zm168 0L513 31.5V0L311.7 111.3h56.6z' />
                </g>
              </svg>
            </ToggleButton>
          </ToggleButtonGroup>
          <TvattHelpButton
            icon
            title={t('helpTitles.help')}
            url={t('helpFiles.mainHelp')}
          />
          <Tooltip title={`Primus: 10.${versionMajor}.${versionMinor}`}>
            <Typography sx={{ color: '#a5a5a5' }} variant='subtitle2' onClick={() => {
              mainDispatchCallback({
                type: SET_HELP_MENU_CONTENT_URL,
                helpMenuContentTitle: t('helpTitles.versionLog'),
                helpMenuContentUrl: t('helpFiles.versionLog')
              })
            }}>
              {t('menu.washer.version')}: {process.env.REACT_APP_VERSION}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    </>
  )
}