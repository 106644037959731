import { Alert, Box, Button, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthsState } from "../../app/auths/AuthsContext";
import { SET_NOTIFICATIONS, useMainDispatch, useMainState } from '../../contexts/MainContext';
import { usePrimusState } from "../../contexts/PrimusContext";
import { SET_INDEXED_DATA, SET_TABLE_DATA, useTableDispatch, useTableState } from "../../contexts/TableContext";
import { dumpAllIndexedData, updateMatchedRows } from '../../services/IndexedDbService';
import { MatchApi } from '../../services/MatchApi';

export const JobMatchItem = ({
  jobData,
  onJobCompletion,
  rowsToMatch,
  noFuzzy
}) => {
  const { t } = useTranslation('translations');

  const [currentRow, setCurrentRow] = useState(0);
  const [matchedRows, setMatchedRows] = useState([]);
  const [progress, setProgress] = useState(0);
  const [progressVariant, setProgressVariant] = useState('indeterminate');
  const [showButton, setShowButton] = useState(false);
  const [status, setStatus] = useState(t('table.default.jobs.matchStarting'));

  const mainDispatch = useMainDispatch();
  const tableDispatch = useTableDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  const { notifications } = useMainState();
  const { selectedList, selectedListName } = usePrimusState();
  const { userLng } = useAuthsState();
  const { data } = useTableState()
  const matchApi = new MatchApi();
  const [isDone, setIsDone] = useState(false)

  const normalize = (value, max) => (value * 100) / max;

  useEffect(() =>  {
    if (isDone) {
      setProgressVariant('indeterminate');
      setShowButton(false);
      const tempTableData = data;
      matchedRows.forEach((row) => {
        tempTableData[tempTableData.findIndex((r) => r.id === row.id)].matchings = [...row.matches];
      });

      tableDispatchCallback({
        type: SET_TABLE_DATA,
        data: [...tempTableData]
      });

      dumpAllIndexedData().then((data) => {
        tableDispatchCallback({
          type: SET_INDEXED_DATA,
          indexedData: data
        });
        onJobCompletion(jobData);
      });
    }
  }, [isDone]);

  const matchRows = useCallback(async () => {
    let tempRows = [];
    Object.keys(rowsToMatch).forEach((artifactId) => {
      if (!artifactId.includes('caption')) {
        tempRows.push(data.find((r) => r.id === artifactId));
      }
    });
    setStatus(t('table.default.jobs.matchWorking'));
    setProgressVariant('determinate');
    let tempMatched = []
    for (const row of tempRows) {
      let match = null;
      if (row.authorityIdValue === '') {
        switch(selectedListName) {
          case 'menu.listSelectorTypes.buildingFacilities':
          case 'menu.listSelectorTypes.building': {
            match = await matchApi.matchBuilding({
              captionToMatch: row.caption ?? '',
              stringToMatch: row.name ?? '',
              begin: row.beginDate?.split('-')[0] ?? '',
              end: row.endDate?.split('-')[0] ?? '',
              fuzzy: !noFuzzy,
              threshold: noFuzzy ? 0.8 : 0.6
            })
            break;
          }

          case 'menu.listSelectorTypes.group': {
            match = await matchApi.matchGroup({
              captionToMatch: row.caption ?? '',
              stringToMatch: row.name ?? '',
              begin: row.beginDate?.split('-')[0] ?? '',
              end: row.endDate?.split('-')[0] ?? '',
              fuzzy: !noFuzzy,
              threshold:noFuzzy ? 0.8 : 0.6
            });

            break;
          }

          case 'menu.listSelectorTypes.organization': {
            match = await matchApi.matchOrganization({
              captionToMatch: row.caption ?? '',
              stringToMatch: row.name ?? '',
              begin: row.beginDate?.split('-')[0] ?? '',
              end: row.endDate?.split('-')[0] ?? '',
              fuzzy: !noFuzzy,
              threshold: noFuzzy ? 0.8 : 0.6
            });

            break;
          }
          // TODO: match against any named, not just person, needs new endpoint in match api
          case 'menu.listSelectorTypes.namedOther':
          case 'menu.listSelectorTypes.person': {
            match = await matchApi.matchPerson({
              captionToMatch: row.caption ?? '',
              stringToMatch: row.name ?? '',
              begin: row.beginDate?.split('-')[0] ?? '',
              end: row.endDate?.split('-')[0] ?? '',
              fuzzy: !noFuzzy,
              threshold: noFuzzy ? 0.8 : 0.6
            })

            break;
          }

          case 'menu.listSelectorTypes.vessel': {
            match = await matchApi.matchNavalVessel({
              captionToMatch: row.caption ?? '',
              stringToMatch: row.name ?? '',
              begin: row.beginDate?.split('-')[0] ?? '',
              // end: row.endDate?.split('-')[0] ?? '',
              fuzzy: !noFuzzy,
              threshold: noFuzzy ? 0.8 : 0.6
            });

            break;
          }

          case 'menu.listSelectorTypes.structure': {
            match = await matchApi.matchStructure({
              captionToMatch: row.caption ?? '',
              stringToMatch: row.name ?? '',
              begin: row.beginDate?.split('-')[0] ?? '',
              end: row.endDate?.split('-')[0] ?? '',
              fuzzy: !noFuzzy,
              threshold: noFuzzy ? 0.8 : 0.6
            });

            break;
          }

          case 'menu.listSelectorTypes.designations':
          case 'menu.listSelectorTypes.subject':
          case 'menu.listSelectorTypes.motifSubject':
          case 'menu.listSelectorTypes.technique':
          case 'menu.listSelectorTypes.materialObject': {
            let matchPayload = {
              captionToMatch: row.name ?? '',
              stringToMatch: row.name ?? '',
              begin: row.beginDate?.split('-')[0] ?? '',
              end: row.endDate?.split('-')[0] ?? '',
              threshold: noFuzzy ? 0.8 : 0.6,
              fuzzy: !noFuzzy,
              lang: userLng ?? ''
            }

            if (selectedListName === 'menu.listSelectorTypes.designations') {
              matchPayload.filteringConcept = 'b473515e-e066-4fd0-b8c0-e74ac082691b';
            }

            if (selectedListName === 'menu.listSelectorTypes.technique') {
              matchPayload.filteringConcept = 'b91cf9a6-9063-4102-9916-89486f9f0c21';
            }

            if (selectedListName === 'menu.listSelectorTypes.materialObject') {
              matchPayload.filteringConcept = '499c76c2-efa8-445b-823b-25e12d957bbe';
            }

            match = await matchApi.matchConcept(matchPayload);

            break;
          }

          default: {
            if (selectedList.startsWith('ct_25')) {
              match = await matchApi.matchConcept({
                captionToMatch: row.name ?? '',
                stringToMatch: row.name ?? '',
                begin: row.beginDate?.split('-')[0] ?? '',
                end: row.endDate?.split('-')[0] ?? '',
                threshold: noFuzzy ? 0.8 : 0.6,
                filteringConcept: '7d7ecc44-8e2f-44a8-bc23-b7f6d7f172ce',
                fuzzy: !noFuzzy,
                lang: userLng ?? ''
              });
            }
            break;
          }
        }

        if (match !== null) {
          setCurrentRow((current) => current + 1);
          tempMatched.push({
            id: row.id,
            matches: match.matchings
          });
          await updateMatchedRows({
            id: row.id,
            matches: match.matchings
          });
        }
      }
    }
    setMatchedRows(tempMatched);
    if (tempRows.length >= 50 && tempMatched.length) {
      setShowButton(true);
    } else {
      setIsDone(true);
    }
  }, [rowsToMatch, t]);

  useEffect(() => {
    setProgress(normalize(currentRow, Object.keys(rowsToMatch).length))
  }, [currentRow, rowsToMatch])

  useEffect(() => {
    matchRows().catch(() => {
      mainDispatchCallback({
        type: SET_NOTIFICATIONS,
        notifications: [...notifications,
          {
            text: t('table.default.jobs.connectionErrorMatchApi'),
            type: 'error'
          }
        ]
      });
    });
  }, [matchRows]);

  return (
    <Alert icon={!showButton ? <CircularProgress variant={progressVariant} value={progress} /> : false}>
      <Box  sx={{ alignItems: 'center', display: 'flex', height: '100%' }}>
        {!showButton ? (
          <>
            {status}
          </>
        ) : (
          <Button onClick={() => setIsDone(true)}>
            {t('table.default.jobs.matchSynchronize')}
          </Button>
        )}
      </Box>
    </Alert>
  )
}