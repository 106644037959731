import { Alert, Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from "react-i18next";
import { usePrimusState } from "../../contexts/PrimusContext";

export const SleepHeader = ({ message }) => {
  const { isAwake, retryCount } = usePrimusState();
  const { t } = useTranslation('translations');

  if (!isAwake && retryCount > 0) {
    return (
      <Box sx={{ flexGrow: '0', flexShrink: '0', flexBasis: 'auto' }}>
        <Alert severity='warning' variant='filled' action={<CircularProgress size='28px' color='white'/>}>
          {message || t('notifications.cannotReachDb')}
        </Alert>
      </Box>
    )
  }
}