import { Dialog } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SET_NOTIFICATIONS, useMainDispatch, useMainState } from '../../contexts/MainContext';
import { usePrimusState } from "../../contexts/PrimusContext";
import { SET_INDEXED_DATA, SET_TABLE_DATA, useTableDispatch, useTableState } from "../../contexts/TableContext";
import { dumpAllIndexedData, toggleEditedRow } from "../../services/IndexedDbService";
import { PrimusApi } from '../../services/PrimusApi';
import { getDuplicatesData } from "../../utils/primusDataHandling";
import { ClickAlert } from "../DetailPanels/ClickAlert";
import { getPrimusUrl } from "../DetailPanels/DetailPanelUtils";
import { MatchDialog } from "./MatchDialog";
import { MergeDialog } from "./MergeDialog";

export const StepperDialog = ({
  columns,
  disableBackdropDismiss=false,
  displayObjectInTitle=false,
  duplicateColumns = null,
  duplicateRows = null,
  matchData = null,
  matchRow = null,
  onCancel,
  onConfirm,
  open=false,
  step0 = false,
  translationKey
}) => {
  const { t } = useTranslation('translations');
  const [activeStep, setActiveStep] = useState(step0 ? -1 : 0);
  const [disableButtons, setDisableButtons] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  const [copyAlertOpen, setCopyAlertOpen] = useState(false);
  const [clickCoordinates, setClickCoordinates] = useState({ x: 0, y: 0 });

  const { notifications } = useMainState();
  const { baseUrl } = usePrimusState();
  const { data: tableData, indexedData } = useTableState();
  const tableDispatch = useTableDispatch();
  const mainDispatch = useMainDispatch();
  const dispatchTableCallback = useCallback(tableDispatch, [tableDispatch]);
  const dispatchMainCallback = useCallback(mainDispatch, [mainDispatch]);

  const isTestEnv = window._env_.REACT_APP_ENVIRONMENT === 'beta' || window._env_.REACT_APP_ENVIRONMENT === 'local';
  const api = useMemo(() => new PrimusApi(baseUrl), [baseUrl]);
  const objectType = tableData?.length ? tableData[0].objectType ?? '' : '';
  const isTerm = !objectType || objectType.startsWith('ct_');

  const dispatchNotification = (type, transKey, duration, transOptions, error, replace) => {
    dispatchMainCallback({
      type: SET_NOTIFICATIONS,
      notifications: [...notifications,
        {
          type: type,
          duration: duration,
          text: t(transKey, transOptions),
          error: error,
          replace: replace
        }
      ]
    });
  };

  const invalidateMergedRows = async (selectedRows, remainingIndex, winnerUsage) => {
    setDisableButtons(false);
    setShowProgress(false);
    setProgress(0);
    setActiveStep(0);

    let tempTableData = tableData;

    await Promise.all(selectedRows.map(async (row, i) => {
      let index = tempTableData.findIndex((r) => r.id === row.id);
      if (remainingIndex && i !== Number(remainingIndex)) {
        tempTableData[index].inValid = true;
        tempTableData[index].frequency = 0;
      } else {
        tempTableData[index].frequency = winnerUsage;
      }
      tempTableData[index].edit = new Date().toISOString();
      await toggleEditedRow(tempTableData[index]).then(dumpAllIndexedData)
        .then((data) => {
          dispatchTableCallback({
            type: SET_INDEXED_DATA,
            indexedData: data
          });
        });
    })).then(() => {
      selectedRows.forEach((row) => {
        let index = tempTableData.findIndex((r) => r.id === row.id);
        let duplicatesData = getDuplicatesData(tempTableData, index, indexedData.markedNoDuplicatesRows);
        tempTableData[index].duplicates = duplicatesData.duplicates;
        tempTableData[index].duplicateData = duplicatesData.duplicateData;
      });

      dispatchTableCallback({
        type: SET_TABLE_DATA,
        data: [...tempTableData]
      });
    });
  };

  const checkStatus = (operation, callbackFn) => {
    setTimeout(() => {
      let min = 0;
      let max = 0;
      let current = 0;

      setDisableButtons(true);
      setShowProgress(true);

      api.getJobStatus().then((jobs) => {
        if (isTestEnv) console.info(`Jobs currently running: `, jobs);

        jobs.messages.forEach((message) => {
          if (operation.queue_id === message.queue_id) {
            current = current + message.progress;
            max = max + 100;

            operation.status = message.status;
            operation.progress = message.progress;

            if (message.status === 'failed') {
              let errorMessage = '';
              if (message.error_message) {
                if (message.error_message?.includes('error.required')) {
                  errorMessage = `${t('table.default.jobs.validationError')}: ${message.error_message}`;
                } else if (message.error_message?.includes('no attribute \'rollback\'')) {
                  errorMessage = t('table.default.jobs.missingDaoError');
                } else {
                  errorMessage =  message.error_message;
                }
              }
              throw new Error(errorMessage || 'no error message');
            }
          }
        });

        let normalizedProgress = (current - min) * 100 / (max - min);
        setProgress(normalizedProgress);
        if (operation.status !== 'step complete') {
          checkStatus(operation, callbackFn);
          dispatchNotification('information',
            translationKey === 'duplicates'
              ? 'table.default.jobs.jobStatusMerge'
              : 'table.default.jobs.jobStatusReplace',
            3000,
            { status: t(`table.default.jobs.jobStatus.${operation.status}`) },
            undefined, true);
        } else {
          callbackFn();
          dispatchNotification('success',
            translationKey === 'duplicates'
              ? 'dialogs.duplicates.rowsMerged'
              : 'dialogs.matchings.rowReplaced',
            5000, undefined, undefined, true);
        }
      }).catch((e) => {
        if (isTestEnv) console.error('[StepperDialog] Error when checking jobstatus', e);
        dispatchNotification('error', 'table.default.jobs.jobstatusError', undefined, undefined, e, true);
      })
    }, 2000);
  }

  const handleClickField = (event, objid, openIn) => {
    if (objid) {
      if (!openIn) {
        const rect = event.target.getBoundingClientRect();
        setClickCoordinates({ x: rect.left, y: rect.top });
        navigator.clipboard.writeText(objid).then(() => setCopyAlertOpen(true));
      } else if (openIn === 'Primus') {
        window.open(`${getPrimusUrl()}/search/artifact?artifactId=${objid}`);
      } else if (openIn === 'KulturNav') {
        window.open(`https://kulturnav.org/${objid}`);
      }
    }
  };

  const commonProps = {
    activeStep,
    api,
    checkStatus,
    columns,
    disableButtons,
    dispatchNotification,
    dispatchTableCallback,
    displayObjectInTitle,
    handleClickField,
    invalidateMergedRows,
    isTestEnv,
    isTerm,
    onCancel,
    onConfirm,
    open,
    matchRow,
    progress,
    setActiveStep,
    showProgress,
    t,
  }

  return (
    <Dialog
      maxWidth={false}
      onClose={(e, reason) => {
        e.stopPropagation();
        if (reason && reason === 'backdropClick' && disableBackdropDismiss) {
          return;
        }
        onCancel();
      }}
      open={open}
    >
      {translationKey === 'matchings' && (
        <MatchDialog matchData={matchData} {...commonProps} />
      )}
      {translationKey === 'duplicates' && (
        <MergeDialog
          duplicateColumns={duplicateColumns}
          duplicateRows={duplicateRows}
          {...commonProps}
        />
      )}
      <ClickAlert
        open={copyAlertOpen}
        setOpen={setCopyAlertOpen}
        clickCoordinates={clickCoordinates}
        message={t('dialogs.duplicates.idCopied')}
      />
    </Dialog>
  )
}