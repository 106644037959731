import React from 'react';
import { Box, CircularProgress, Fade, LinearProgress } from '@mui/material';

export const TvattLoader = ({ fullscreen=false, show=true }) => {
  if (fullscreen && show) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <Fade
          in={show}
          style={{
            transitionDelay: '800ms'
          }}
          unmountOnExit={true}
        >
          <CircularProgress />
        </Fade>
      </Box>
    )
  }

  if (show) {
    return (
      <div>
        <Fade
          in={show}
          style={{
            transitionDelay: '800ms'
          }}
          unmountOnExit={true}
        >
          <LinearProgress />
        </Fade>
      </div>
    )
  }
  else {
    return null;
  }
}
