import React, { createContext, useContext, useReducer } from 'react';

import { localStorageKeys } from "../../declarations/localStorageKeys";

export const SET_USER_DATA = 'setUserData';
export const USER_LOGGED_IN = 'loggedIn';
export const USER_LOGGED_OUT = 'loggedOut';
export const SET_USER_LNG = 'setUserLng';

const AuthsDispatchContext = createContext(undefined);
const AuthsStateContext = createContext(undefined);

const initialState = {
  userData: {},
  userIsAuthenticated: false,
  userLng: localStorage.getItem(localStorageKeys.lng)
}

const authsReducer = (state, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      let lng = localStorage.getItem(localStorageKeys.lng)
      lng = lng?.length === 2 ? lng : action.userData?.locale
      return {
        ...state,
        userData: action.userData,
        userLng: lng
      }
    case USER_LOGGED_IN:
      return {
        ...state,
        userIsAuthenticated: true
      }
    case USER_LOGGED_OUT:
      return {
        ...state,
        userIsAuthenticated: false
      }
    case SET_USER_LNG:
      return {
        ...state,
        userLng: action.userLng
      }
    default:
      throw new Error(`[AuthsContext] Unhandled action type: ${action.type}`);
  }
};

export const AuthsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authsReducer, {...initialState}, undefined);

  return (
    <AuthsStateContext.Provider value={state}>
      <AuthsDispatchContext.Provider value={dispatch}>
        {children}
      </AuthsDispatchContext.Provider>
    </AuthsStateContext.Provider>
  );
};

export const useAuthsDispatch = () => {
  const context = useContext(AuthsDispatchContext);

  if (undefined === context) {
    throw new Error('[AuthsContext] useSearchResultDetailsDispatch must be used within a AuthsProvider');
  }
  else {
    return context;
  }
}

export const useAuthsState = () => {
  const context = useContext(AuthsStateContext);

  if(undefined === context) {
    throw new Error('[AuthsContext] useSearchResultState must be used within a AuthsProvider');
  } else {
    return context
  }
}
