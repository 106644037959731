import { CheckCircleTwoTone, HighlightOffTwoTone } from "@material-ui/icons";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { SleepHeader } from "../components/SleepHeader/SleepHeader";
import { TvattLoader } from "../components/TvattLoader/TvattLoader";
import { usePrimusState } from "../contexts/PrimusContext";
import {
  COLLECTIONS_WRITE_RIGHT_ID,
  GLOBAL_LISTS_RIGHTS_ID,
  LOOKUP_LISTS_RIGHTS_ID,
  NAMES_RIGHTS_ID,
  rightsMap,
  SUPEROBJECT_TYPE_AGENT_ID
} from "../declarations/UUIDs";
import { PrimusApi } from "../services/PrimusApi";
import { NoAccessContent } from "./auths/NoAccessContent";

const necessaryRights = {
  [LOOKUP_LISTS_RIGHTS_ID]: false,
  [GLOBAL_LISTS_RIGHTS_ID]: false,
  [NAMES_RIGHTS_ID]: false,
};

export const PrimusRights = ({ children }) => {
  const { t } = useTranslation('translations');
  const { baseUrl, database } = usePrimusState();

  const [allRights, setAllRights] = useState({ ...necessaryRights });
  const [allCollections, setAllCollections] = useState({});
  const [hasRights, setHasRights] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleRights = (user) => {
    let tempRights = { ...necessaryRights };
    user.rights?.forEach((right) => {
      if (tempRights.hasOwnProperty(right.user_rights_id)) {
        tempRights[right.user_rights_id] = true;
      }
    });
    setAllRights(tempRights);
  }
  const handleCollections = (collections, user) => {
    let tempColls = {};
    collections?.forEach((coll) => {
      let temp = user.collections.find((c) => c.collection_id === coll.artifact_id)
      tempColls[coll.artifact_name] = temp ? temp.collection_rights_id === COLLECTIONS_WRITE_RIGHT_ID : false;
    })
    setAllCollections(tempColls);
  }

  useEffect(() => {
    setHasRights(!!Object.values(allRights).every(value => value === true)
      && !!Object.values(allCollections).some(value => value === true));
  }, [allRights, allCollections]);

  useEffect(() => {
    if (!!baseUrl) {
      setLoading(true);
      const primus = new PrimusApi(baseUrl);
      primus.getUser()
        .then((userConfig) =>
          !!userConfig?.user?.artifact_id && primus.getArtifact(userConfig.user.artifact_id)
            .then((user) => {
              !!user && handleRights(user);
              return user;
            }))
        .then((user) => primus.getCollections([SUPEROBJECT_TYPE_AGENT_ID])
          .then((colls) => !!colls && handleCollections(colls, user)))
        .then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [baseUrl])

  if (loading) {
    return (
      <>
        <SleepHeader />
        <TvattLoader fullscreen show/>
      </>
    )
  }

  return (
    hasRights || !baseUrl
      ? children
      : (
        <Box display='flex' flexDirection='column' alignItems='center' marginTop='32px' gap='4px' height='100%' p='16px'>
          <NoAccessContent>
            <Typography>
              {t('primusRights.header', { database: database })}
            </Typography>
            <Typography sx={{ marginBottom: '24px' }}>
              {t('primusRights.collectionInfo')}
            </Typography>
            {Object.entries(allRights).map(([k, v]) => (
              <Box display='flex' key={k} gap='8px' width='420px' justifyContent='space-between'>
                <Typography>{t(rightsMap[k])}</Typography>
                {v ? <CheckCircleTwoTone htmlColor='green'/> : <HighlightOffTwoTone htmlColor='red'/>}
              </Box>
            ))}
            {Object.entries(allCollections).map(([k, v]) => (
              <Box display='flex' key={k} gap='8px' width='420px' justifyContent='space-between'>
                <Typography>{`${t('primusRights.collection')}» ${k}`}</Typography>
                {v ? <CheckCircleTwoTone htmlColor='green'/> : <HighlightOffTwoTone htmlColor='red'/>}
              </Box>
            ))}
          </NoAccessContent>
        </Box>
      )
  );
}
