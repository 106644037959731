import { Add } from "@material-ui/icons";
import { Autocomplete, Box, Button, Chip, CircularProgress, Divider, TextField, Typography } from "@mui/material";
import { debounce } from "@mui/material/utils";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";

import { SET_NOTIFICATIONS, useMainDispatch, useMainState } from "../../contexts/MainContext";
import { usePrimusState } from "../../contexts/PrimusContext";
import {
  SET_INDEXED_DATA,
  SET_TABLE_DATA,
  SET_TABLE_PINNED_ROWS,
  useTableDispatch,
  useTableState
} from "../../contexts/TableContext";
import { dumpAllIndexedData, toggleEditedRow } from "../../services/IndexedDbService";
import { PrimusApi } from "../../services/PrimusApi";
import { getDuplicatesData } from "../../utils/primusDataHandling";
import { getTermCaption, getTermHierarchy, reAnalyzeTerm } from "../../utils/primusTermDataHandling";
import useIsAwake from "../../utils/useIsAwake";
import { SleepHeader } from "../SleepHeader/SleepHeader";
import { ClickAlert } from "./ClickAlert";
import { DetailPanelActionMenu } from "./DetailPanelActionMenu";
import { DetailPanelUsageList } from "./DetailPanelUsageList";
import { getClickableItem, getPrimusUrl, getTermPinItem } from "./DetailPanelUtils";
import { SidePanelChildrenList } from "./SidePanelChildrenList";
import { SidePanelUsageList } from "./SidePanelUsageList";

export const TermDetailPanel = ({
  disableEditing=false,
  rowData,
  toggleIsExpanded
}) => {
  const tableDispatch = useTableDispatch();
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);
  const mainDispatch = useMainDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);

  const [description, setDescription] = useState(rowData.description);
  const [name, setName] = useState(rowData.name);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [saving, setSaving] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [loadingSideActions, setLoadingSideActions] = useState(false);

  const { baseUrl, descriptionObject, selectedList, versionMajor, versionMinor, selectedListStatus, isAwake } = usePrimusState();
  const { notifications } = useMainState();
  const { data, indexedData, pinnedRows } = useTableState();
  const { t } = useTranslation('translations');
  const [usages, setUsages] = useState([]);
  const { isChecking, checkIsAwake } = useIsAwake(1000);

  const [copyAlertOpen, setCopyAlertOpen] = useState(false);
  const [clickCoordinates, setClickCoordinates] = useState({ x: 0, y: 0 });

  const [usagePanelOpen, setUsagePanelOpen] = useState(false);
  const [childrenPanelOpen, setChildrenPanelOpen] = useState(false);

  const isOutline = selectedList.includes('ct_25');

  const dispatchError = (transKey, error, transOptions) => {
    mainDispatchCallback({
      type: SET_NOTIFICATIONS,
      notifications: [...notifications,
        {
          type: 'error',
          text: t(transKey, transOptions),
          error: error
        }
      ]
    });
  };

  const api = useMemo(() => new PrimusApi(baseUrl), [baseUrl]);
  const [pathElements, setPathElements] = useState(rowData.mPath.split('/'));
  const [parentElements, setParentElements] = useState(rowData.parentPath.split('» '));
  const initialOption = {
    id: rowData.parentId,
    label: parentElements[parentElements.length - 1],
    path: rowData.parentPath,
    level: parentElements.length,
    mPath: rowData.mPath.split('/').slice(0, -2).join('/')
  }
  const [children, setChildren] = useState([]);

  const [options, setOptions] = useState(rowData.parentId ? [initialOption] : []);

  const [selected, setSelected] = useState(
    !!rowData.parentId && (!isOutline || rowData.parentId !== selectedList)
      ? initialOption
      : null
  );

  const saveTerm = async () => {
    setSaving(true);

    if (selected?.id !== rowData.parentId || description !== rowData.description || name !== rowData.name) {
      let currentlyAwake = await checkIsAwake();
      if (currentlyAwake) {
        api.getArtifact(rowData.id).then((result) => {
          let temp = result;

          if (selected?.id) {
            temp.parent_path = selected.path
            temp.full_path = `${selected.path}» ${name}`;
            temp.parent_id = selected.id;
            temp.level = selected.level + 1;
            temp.m_path = `${selected.mPath}/${selected.id}`;
          } else if (isOutline) {
            temp.parent_id = selectedList;
            temp.level = 2;
            temp.parent_path = 'Outline';
            temp.full_path = `Outline» ${name}`;
            temp.m_path = `/${selectedList}`;
          } else {
            temp.parent_id = '';
            temp.level = 1;
            temp.parent_path = '';
            temp.full_path = '';
            temp.m_path = '';
          }
          setPathElements(temp.m_path.split('/'));
          setParentElements(temp.parent_path.split('» '));

          if (name !== rowData.name) {
            temp.name.name = name;
          }

          if (description !== rowData.description) {
            if (temp.description === null) {
              temp.description = { ...descriptionObject };
              temp.description.description = description;
            } else {
              temp.description.description = description;
            }
          }

          return temp;
        }).then((artifact) => {
          api.putArtifact(artifact).then(() => {
            updateTable(artifact);
            setSaving(false);
            toggleIsExpanded(false);
          }).catch((e) => dispatchError('table.default.jobs.putArtifactError', e, { name: artifact.name?.name }));
        }).catch((e) => dispatchError('table.default.jobs.getArtifactError', e, { name: rowData.name }));
      }
    }
  }

  const updateTable = (artifact, usageNr) => {
    let tempTableData = data;
    let index = tempTableData.findIndex((r) => r.id === artifact.artifact_id);
    if (index !== -1) {
      tempTableData[index].name = artifact.name.name;
      tempTableData[index].caption = getTermCaption(artifact);
      tempTableData[index].description = artifact.description?.description || '';

      tempTableData[index].level = artifact.level || 1;
      tempTableData[index].mPath = artifact.m_path ?? '';
      tempTableData[index].parentPath = artifact.parent_path ?? '';
      tempTableData[index].parentId = artifact.parent_id ?? '';
      tempTableData[index].hierarchy = getTermHierarchy(artifact);

      let duplicatesData = getDuplicatesData(tempTableData, index, indexedData.markedNoDuplicatesRows);
      tempTableData[index].duplicates = duplicatesData.duplicates;
      tempTableData[index].duplicateData = duplicatesData.duplicateData;

      tempTableData[index].edit = new Date().toISOString();
      toggleEditedRow(tempTableData[index]).then(dumpAllIndexedData)
        .then((data) => {
          tableDispatchCallback({
            type: SET_INDEXED_DATA,
            indexedData: data
          });
        });

      tempTableData[index].frequency = usageNr ?? tempTableData[index].frequency;

      let analysisData = reAnalyzeTerm(t, tempTableData[index]);
      tempTableData[index].errors = analysisData.errors;
      tempTableData[index].messages = analysisData.messages;

      tableDispatchCallback({
        type: SET_TABLE_DATA,
        data: [...tempTableData]
      });
    }
  }

  const updateOptions = useMemo(() => debounce((query) => {
    if (query) {
      setLoadingOptions(true);
      api.getTermTypes(isOutline ? 'ct_25' : selectedList, query).then((result) => {
        let tempOptions = [];

        result.artifacts.forEach((item) => {
          if (item.artifact_id !== selectedList && item.artifact_id !== rowData.id) {
            tempOptions.push({
              label: item['name.name'],
              id: item.artifact_id,
              path: item.full_path,
              level: item.level,
              mPath: item.m_path
            });
          }
        });

        setOptions(tempOptions);
      }).then(() => setLoadingOptions(false));
    } else {
      setOptions([]);
    }
  }, 500), []);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open])

  useEffect(() => updateOptions(query), [query])

  const handleClickField = (event, objid, openIn) => {
    if (objid) {
      if (!openIn) {
        const rect = event.target.getBoundingClientRect();
        setClickCoordinates({ x: rect.left, y: rect.top });
        navigator.clipboard.writeText(objid).then(() => setCopyAlertOpen(true));
      } else {
        window.open(`${getPrimusUrl()}/search/artifact?artifactId=${objid}`);
      }
    }
  };

  const isIdInCurrentTable = (id) => data.some((row) => id === row.id);

  const handlePinning = (objid) => {
    if (isIdInCurrentTable(objid)) {
      const existingIndex = pinnedRows.top.indexOf(objid);
      const tempRows = existingIndex < 0
        ? [...pinnedRows.top, objid]
        : [...pinnedRows.top.slice(0, existingIndex), ...pinnedRows.top.slice(existingIndex + 1)];
      tableDispatchCallback({
        type: SET_TABLE_PINNED_ROWS,
        pinnedRows: {
          top: tempRows,
          bottom: pinnedRows.bottom
        }
      })
    } else {
      dispatchError('notifications.cannotPin', undefined, { status: t(`table.default.header.${selectedListStatus}`) });
    }
  };

  useEffect(() => {
    api.getTermChildren(rowData.id).then(setChildren);
  }, [rowData.id])

  return (
    <>
      {!isAwake && <SleepHeader/>}
      <Box display='flex' width='100%' justifyContent='space-between' gap={2}>
        <Box alignItems='center' display='flex' flexDirection='column' gap={2} flex='1'>
          <Autocomplete
            disabled={rowData.hasAuthority || disableEditing}
            fullWidth
            isOptionEqualToValue={(option, value) => option.id === value.id}
            loading={loadingOptions}
            onChange={(event, selection) => setSelected(selection)}
            onClose={() => {setOpen(false)}}
            onInputChange={(event, value) => setQuery(value)}
            onOpen={() => {setOpen(true)}}
            open={open}
            options={options}
            value={selected}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={t('table.default.detailpane.mainTerm')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingOptions ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )
            }}
          />

          <TextField
            disabled={rowData.hasAuthority || disableEditing || (versionMajor < 1 && versionMinor < 236)}
            fullWidth
            label={t('table.default.detailpane.term')}
            name='name'
            onChange={(event) => {
              setName(event.target.value);
            }}
            value={name}
          />

          <TextField
            disabled={rowData.hasAuthority || disableEditing}
            fullWidth
            label={t('table.default.detailpane.description')}
            multiline
            name={'description'}
            onChange={(event) => {
              setDescription(event.target.value)
            }}
            rows={4}
            value={description}
          />

          <Box
            sx={{
              alignItems: 'center',
              alignSelf: 'flex-start',
              display: 'flex',
              flexDirection: 'row',
              gap: 2
            }}
          >
            {!disableEditing && (
              <Button
                color='info'
                disabled={isChecking || rowData.hasAuthority || saving ||
                  (name === rowData.name && description === rowData.description &&
                    (selected?.id === rowData.parentId || (selected === null && rowData.parentId === '')))}
                onClick={saveTerm}
                variant='contained'
              >
                {t('table.default.detailpane.save')}
              </Button>
            )}

            {saving &&
              <CircularProgress size={25} />
            }
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' flex='1'>
          <Box display='flex' marginBottom={0.5} alignItems='center' gap={1} fontSize='1rem'>
            ID:
            {getClickableItem(rowData.id, rowData.objectType, rowData.caption, handleClickField)}
          </Box>
          <Box display='flex' marginBottom={0.5} alignItems='center' gap={1} fontSize='1rem'>
            {`${t('table.default.detailpane.collection')}:`}
            {getClickableItem(rowData.collectionId, 'Collection', rowData.collectionIdValue, handleClickField, true)}
          </Box>
          <Typography>{t('table.default.detailpane.information')}</Typography>
          <Typography sx={{ fontSize: '14px' }}>
            {rowData.solrData}
          </Typography>
          {(pathElements?.length > 2 || (!isOutline && pathElements?.length > 1)) && (
            <Box display='flex' gap='8px'>
              {`${t('table.default.detailpane.parent')}:`}
              {pathElements.map((element, index) => {
                if ((index > 1 && isOutline) || (index > 0 && !isOutline)) {
                  let id = pathElements[index];
                  return getTermPinItem(id, parentElements[index - 1], handlePinning, pinnedRows.top.indexOf(id) > -1);
                }
                return null;
              })}
            </Box>
          )}
          {!!children.length && (
            <>
              <Box display='flex' flexWrap='wrap' gap='8px'>
                {`${t('table.default.detailpane.children')}:`}
                {children.slice(0, 5).map((c) => (
                  getTermPinItem(c.id, c.name, handlePinning, pinnedRows.top.indexOf(c.id) > -1)
                ))}
                {children.length > 5 && (
                  <Chip
                    size='small'
                    sx={{ width: 'fit-content' }}
                    onClick={() => setChildrenPanelOpen(true)}
                    label={
                      <Box display='flex' alignItems='center' gap={0.5}>
                        {t('table.default.detailpane.seeAll')}
                        <Add sx={{ fontSize: '16px !important' }} />
                      </Box>
                    }
                  />
                )}
              </Box>
              <Divider flexItem variant='fullWidth' sx={{ margin: '6px' }} />
            </>
          )}
          <DetailPanelUsageList
            usages={usages}
            handleClickField={handleClickField}
            sidePanelOpen={usagePanelOpen}
            setSidePanelOpen={setUsagePanelOpen}
            loading={loadingSideActions}
            t={t}
          />
        </Box>
        {!disableEditing && (
          <Box>
            <DetailPanelActionMenu
              row={rowData}
              refreshRow={updateTable}
              dispatchError={dispatchError}
              setUsages={setUsages}
              loading={loadingSideActions}
              setLoading={setLoadingSideActions}
            />
          </Box>
        )}
        <ClickAlert
          open={copyAlertOpen}
          setOpen={setCopyAlertOpen}
          clickCoordinates={clickCoordinates}
          message={t('dialogs.duplicates.idCopied')}
        />
        <SidePanelUsageList
          usages={usages}
          handleClickField={handleClickField}
          open={usagePanelOpen}
          setOpen={setUsagePanelOpen}
          t={t}
        />
        <SidePanelChildrenList
          childrenList={children}
          handleClick={handlePinning}
          open={childrenPanelOpen}
          setOpen={setChildrenPanelOpen}
          t={t}
          pinnedRows={pinnedRows.top}
        />
      </Box>
    </>
  )
}