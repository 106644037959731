import { kitFetch } from '@ekultur/fetch';
import { Box } from '@mui/material';
import i18n from "i18next";
import React, { useCallback, useEffect, useState } from 'react';

import { TvattLoader } from "../../components/TvattLoader/TvattLoader";
import { SET_USER_DATA, useAuthsDispatch, useAuthsState } from './AuthsContext';
import { NoAccessView } from "./NoAccessView";

const hasWashAccess = (appAccess) => {
  if (appAccess?.apps?.length && appAccess.apps.some(app => app.id === window._env_.REACT_APP_ID)) {
    return true;
  }
  if (appAccess?.museums?.length) {
    return appAccess.museums.some(museum =>
      museum.applications?.some(app => app.id === window._env_.REACT_APP_ID)
    );
  }
  return false;
}
export const WithUserData = ({children}) => {
  const authsDispatch = useAuthsDispatch();
  const authsDispatchCallback = useCallback(action => authsDispatch(action), [authsDispatch]);
  const { userData, userIsAuthenticated, userLng } = useAuthsState();
  const [ washAccess, setWashAccess ] = useState(false);

  useEffect(() => setWashAccess(hasWashAccess(userData?.appAccess)), [userData]);

  useEffect(() => {
    if (userIsAuthenticated) {
      kitFetch('/authz/authorize/userData').then((json) => {
        authsDispatchCallback({
          type: SET_USER_DATA,
          userData: json
        });
      }).catch((error) => {
        console.error('[WithUserData] ', error);
      });
    }
  }, [authsDispatchCallback, userIsAuthenticated])

  useEffect(() => {
    i18n.changeLanguage(userLng)
  }, [userLng])

  if (!washAccess && !userData.appAccess) {
    return <TvattLoader fullscreen show/>;
  }

  return washAccess ? (
    <Box sx={{display:'flex'}}>
      {children}
    </Box>
  ) : (
    <NoAccessView />
  );
}