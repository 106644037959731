import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button, Dialog, DialogActions, DialogTitle, MenuItem, TextField } from '@mui/material';
import { usePrimusState } from "../../contexts/PrimusContext";
import { useTranslation } from 'react-i18next';

import {
  SET_INDEXED_DATA,
  SET_TABLE_DATA,
  SET_TABLE_PINNED_ROWS,
  useTableDispatch,
  useTableState
} from "../../contexts/TableContext";
import { PERSON_TYPE_ID } from "../../declarations/UUIDs";
import { dumpAllIndexedData, toggleEditedRow } from "../../services/IndexedDbService";
import { PrimusApi } from "../../services/PrimusApi";
import { SET_NOTIFICATIONS, useMainDispatch, useMainState } from "../../contexts/MainContext";

export const ChangeTypeDialog = ({
  onCancel,
  onConfirm,
  open
}) => {
  const [displayData, setDisplayData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('ct_23-784b329e-5a5d-4db7-ab34-f316d0b54f34');
  const [loading, setLoading] = useState(false);

  const { notifications } = useMainState();
  const { knownAgentTypes, baseUrl } = usePrimusState();
  const { data: tableData, selectedData, pinnedRows } = useTableState();
  const mainDispatch = useMainDispatch();
  const tableDispatch = useTableDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);
  const { t } = useTranslation('translations');

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'id',
        header: t('dialogs.type.tableHeaders.id')
      },
      {
        accessorKey: 'caption',
        header: t('dialogs.type.tableHeaders.caption')
      },
      {
        accessorKey: 'type',
        header: t('dialogs.type.tableHeaders.type')
      }
    ]
  }, [])

  useEffect(() => {
    if (open) {
      if (Object.keys(selectedData).length > 0) {
        let rows = [];

        Object.keys(selectedData).forEach((artifactId) => {
          if (!artifactId.includes('caption')) {
            let row = tableData[tableData.findIndex((r) => r.id === artifactId)];

            if (row.authorityIdValue === '') {
              rows.push({
                caption: row.caption,
                id: artifactId,
                type: row.agentTypeIdValue
              });
            }
          }
        });

        setDisplayData(rows);
      }
    }
  }, [open, selectedData])

  const onTypeChange = () => {
    setLoading(false);
    let selectedIds = Object.keys(selectedData);
    let agent = knownAgentTypes.find((a) => a.id === selectedOption);
    let api = new PrimusApi(baseUrl);
    Promise.all(selectedIds.map((id) => {
      return api.getArtifact(id).then((res) => {
        if (res) {
          let temp = res;
          temp['agent_type_id'] = agent.id;
          temp['agent_type_id_value'] = agent.label;
          if (agent.id === PERSON_TYPE_ID) {
            let firstName = res.first_name?.first_name || '';
            let lastName = res.last_name?.last_name || '';
            let middleName = res.middle_name?.middle_name || '';
            let names = res.name?.name.split(',');
            if (names?.length) {
              if (names.length > 1) {
                firstName = names[1].trim();
                lastName = names[0].trim();
              } else {
                lastName = names[0].trim();
              }
            }
            temp.first_name =  { ...temp.first_name, first_name: firstName };
            temp.middle_name =  { ...temp.middle_name, middle_name: middleName };
            temp.last_name =  { ...temp.last_name, last_name: lastName };
          }
          return api.putArtifact(temp);
        }
      }).catch((e) => {
        throw new Error(`${id}: ${e}`);
      });
    })).then((results) => {
      if (results?.length && pinnedRows?.top?.length) {
        tableDispatchCallback({
          type: SET_TABLE_PINNED_ROWS,
          pinnedRows: {
            top: [...pinnedRows.top.filter((row) => !results.some((res) => row === res.artifact_id))],
            bottom: pinnedRows.bottom
          }
        });
      }
    }).then(() => {
      let tempTable = tableData.map((row) => {
        if (selectedIds.find((id) => id === row.id)) {
          let tempRow = row;
          tempRow['agentTypeId'] = agent.id;
          tempRow['agentTypeIdValue'] = agent.label;
          tempRow['edit'] = new Date().toISOString();
          toggleEditedRow(tempRow).then(dumpAllIndexedData)
            .then((data) => {
              tableDispatchCallback({
                type: SET_INDEXED_DATA,
                indexedData: data
              });
            });
          return tempRow;
        } else {
          return row;
        }
      });
      tableDispatchCallback({
        type: SET_TABLE_DATA,
        data: [...tempTable]
      });
      onConfirm();
      setLoading(false);
    }).catch((e) => {
      onCancel();
      setLoading(false);
      mainDispatchCallback({
        type: SET_NOTIFICATIONS,
        notifications: [...notifications,
          {
            text: t('table.default.jobs.errorChangingType'),
            type: 'error',
            error: e,
          }
        ]
      });
    })
  };

  return (
    <Dialog maxWidth={false} open={open}>
      <DialogTitle>
        {t('dialogs.type.title')}
      </DialogTitle>
        <MaterialReactTable
          columns={columns}
          data={displayData}
          enableBottomToolbar={false}
          enablePagination={false}
          enableTopToolbar={false}
          muiTableContainerProps={{ sx: { width: '90vw', maxHeight: 'calc(100vh - 200px)' } }}
        />
      <DialogActions sx={{ maxWidth: '100%' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%'
          }}
        >
          <TextField
            defaultValue=''
            label={t('dialogs.type.selectType')}
            onChange={(event) => setSelectedOption(event.target.value)}
            disabled={loading}
            select
            sx={{
              width: 'min(50%, 300px)',
              marginRight: 'auto'
            }}
            value={selectedOption}
          >
            {knownAgentTypes.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {t(option.label)}
                </MenuItem>
              )
            })}
          </TextField>
          <Button disabled={loading} onClick={onCancel}>
            {t('dialogs.type.cancel')}
          </Button>
          <Button disabled={loading || displayData.length < 1} onClick={onTypeChange}>
            {t('dialogs.type.confirm')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}