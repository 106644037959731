import { Box, CircularProgress, Fade, MenuItem, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'whatwg-fetch';

import { useAuthsState } from '../../app/auths/AuthsContext';
import { SET_NOTIFICATIONS, useMainDispatch, useMainState } from "../../contexts/MainContext";
import { SET_DATABASE, SET_DESCRIPTION_OBJECT, usePrimusDispatch, usePrimusState } from "../../contexts/PrimusContext";
import { RESET_TABLE_STATE, useTableDispatch } from "../../contexts/TableContext";
import { EkulturAPI } from '../../services/EkulturAPI';
import { PrimusApi } from "../../services/PrimusApi";
import useIsAwake from "../../utils/useIsAwake";

export const DatabaseSelector = () => {
  const [availableServers, setAvailableServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState();
  const [loading, setLoading] = useState(true);

  const dispatch = usePrimusDispatch();
  const tableDispatch = useTableDispatch();
  const dispatchCallback = useCallback(dispatch, [dispatch]);
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);
  const mainDispatch = useMainDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);

  const { notifications } = useMainState();
  const { t } = useTranslation('translations');
  const { userData } = useAuthsState();
  const { checkIsAwake } = useIsAwake();
  const { baseUrl, isAwake } = usePrimusState();

  const dispatchError = (transKey, error) => {
    mainDispatchCallback({
      type: SET_NOTIFICATIONS,
      notifications: [...notifications,
        {
          type: 'error',
          text: t(transKey),
          error: error
        }
      ]
    });
  };

  useEffect(() => {
    if (baseUrl !== '') {
      let primus = new PrimusApi(baseUrl);

      primus.createDescriptionObject().then((descriptionObject) => {
        dispatchCallback({
          type: SET_DESCRIPTION_OBJECT,
          descriptionObject: descriptionObject
        });
      }).catch((e) => dispatchError('table.default.jobs.errorDatabaseContextData', e));
    }
  }, [baseUrl])

  useEffect(() => {
    const api = new EkulturAPI();

    if (!availableServers.length) {
      api.getAvailablePrimusInstances(userData?.uniqueId).then((res) => {
        let servers = res ?? [];
        if (!userData?.appAccess?.apps?.find(app => app.id === window._env_.REACT_APP_ID)) {
          let museums = userData?.appAccess?.museums;
          if (museums?.length) {
            servers = servers.filter((s) => {
              let museum = museums.find(m => m.id === s.id);
              return museum?.applications?.find(app => app.id === window._env_.REACT_APP_ID);
            });
          } else {
            servers = [];
          }
        }
        if (window.location.host.startsWith('localhost')) {
          servers.push({
            id: -1,
            name: 'Localhost',
            serverUrl: 'http://localhost:5000'
          });
        }
        setAvailableServers(servers);
        setSelectedServer(servers.find((s) => baseUrl === s.serverUrl) || servers[0]);
      }).finally(() => {
        setLoading(false);
      }).catch((e) => dispatchError('table.default.jobs.errorGettingInstances', e));
    } else {
      setLoading(false);
    }
  }, [userData])

  const handleSelectDatabase = (event) => {
    if (event.target.value !== selectedServer.serverUrl) {
      let server = availableServers.find((s) => s.serverUrl === event.target.value)
      setSelectedServer(server)
      tableDispatchCallback({ type: RESET_TABLE_STATE });
    }
  }

  const configureDatabase = async (server) => {
    await checkIsAwake();
    if (!!isAwake) {
      try {
        const primusApi = new PrimusApi(server.serverUrl);
        const conf = await primusApi.getClientConfig();
        dispatchCallback({
          type: SET_DATABASE,
          baseUrl: server.serverUrl,
          database: server.name,
          versionMajor: parseInt(conf.VERSION.split('.')[1]),
          versionMinor: parseInt(conf.VERSION.split('.')[2]),
        });
      } catch (error) {
        console.error("Failed to fetch database config:", error);
      }
    }
  };

  useEffect(() => {
    if (!selectedServer) return;
    dispatchCallback({
      type: SET_DATABASE,
      baseUrl: selectedServer.serverUrl,
      database: selectedServer.name,
      versionMajor: -1,
      versionMinor: -1,
    });
    configureDatabase(selectedServer);
  }, [selectedServer]);

  return (
    <>
      {!loading ? (
        <Fade in={true} unmountOnExit={true}>
          <Box sx={{
            padding: '0 .5rem',
          }}>
            <TextField
              label={t('menu.washer.selectPrimusServer')}
              disabled={!availableServers.length}
              fullWidth
              onChange={handleSelectDatabase}
              select
              value={selectedServer.serverUrl}
            >
              {availableServers.map((server) => (
                <MenuItem key={server.id} value={server.serverUrl}>
                  {server.name}
                </MenuItem>
              ))
              }
            </TextField>
          </Box>
        </Fade>
      ) : (
        <Fade in={true} unmountOnExit={true}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '8px 10px',
              width: '100%'
            }}
          >
            <CircularProgress />
          </Box>
        </Fade>
      )}
    </>
  )
}