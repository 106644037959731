import { useState, useCallback, useEffect } from "react";
import { SET_IS_AWAKE, SET_RETRY_COUNT, usePrimusDispatch, usePrimusState } from "../contexts/PrimusContext";
import { PrimusApi } from "../services/PrimusApi";

const useIsAwake = (timeout = 1000, checkInterval = 1000, maxRetries = 100) => {
  const [isChecking, setIsChecking] = useState(false);
  const { baseUrl, isAwake, retryCount } = usePrimusState();
  const dispatch = usePrimusDispatch();
  const dispatchCallback = useCallback(dispatch, [dispatch]);

  const checkIsAwake = useCallback(async () => {
    if (isChecking || retryCount >= maxRetries) return;

    setIsChecking(true);
    const api = new PrimusApi(baseUrl);
    let controller = new AbortController();

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => {
        controller.abort();
        reject(new Error("Timeout reached"))
      }, timeout)
    );

    try {
      const result = await Promise.race([api.getIsAwake(controller.signal), timeoutPromise]);

      if (isAwake !== !!result) {
        dispatchCallback({
          type: SET_IS_AWAKE,
          isAwake: !!result,
          retryCount: retryCount
        });
      }
    } catch (error) {
      console.warn(`[Appempt ${retryCount}/${maxRetries}] Health check failed or timed out:`, error.message);
      if (!!isAwake) {
        dispatchCallback({
          type: SET_IS_AWAKE,
          isAwake: false
        });
      }
    } finally {
      setIsChecking(false);
    }
  }, [timeout, isAwake, isChecking]);

  useEffect(() => {
    if (!isAwake) {
      const interval = setInterval(() => {
        if (retryCount < maxRetries) {
          dispatchCallback({
            type: SET_RETRY_COUNT,
            retryCount: retryCount + 1
          });
          checkIsAwake();
        } else {
          clearInterval(interval);
          console.warn(`Max retries (${maxRetries}) reached, stopping the check.`);
        }
      }, checkInterval);

      return () => clearInterval(interval);
    }
  }, [isAwake, checkIsAwake, checkInterval]);

  return { isChecking, checkIsAwake };
};

export default useIsAwake;
