import React, { createContext, useContext, useReducer } from 'react';
import 'whatwg-fetch';

import { localStorageKeys } from "../declarations/localStorageKeys";
import { PrimusApi } from "../services/PrimusApi";

const PrimusDispatchContext = createContext(null);
const PrimusStateContext = createContext(null);

const initialState = {
  baseUrl: localStorage.getItem(localStorageKeys.selectedBaseUrl) || '',
  database: localStorage.getItem(localStorageKeys.selectedDatabase) || '',
  descriptionObject: null,
  knownAgentTypes: [],
  selectedList: localStorage.getItem(localStorageKeys.selectedPrimusList) || '',
  selectedListName: localStorage.getItem(localStorageKeys.selectedPrimusListName) || '',
  selectedListStatus: 'valid',
  versionMajor: -1,
  versionMinor: -1,
  operations: [],
  isAwake: localStorage.getItem(localStorageKeys.isAwake) === 'true',
  retryCount: 0
}

const Reducer = (state, action) => {
  switch (action.type) {
    case LOAD_INSTANCE_CONFIG: {
      return {
        ...state,
        versionMajor: action.versionMajor,
        versionMinor: action.versionMinor
      }
    }

    case SET_DATABASE: {
      window.localStorage.setItem(localStorageKeys.selectedBaseUrl, action.baseUrl);
      window.localStorage.setItem(localStorageKeys.selectedDatabase, action.database);
      window.localStorage.setItem(localStorageKeys.selectedPrimusList, '');
      window.localStorage.setItem(localStorageKeys.selectedPrimusListName, '');
      window.localStorage.setItem(localStorageKeys.isAwake, 'false');

      return {
        ...state,
        baseUrl: action.baseUrl,
        database: action.database,
        descriptionObject: null,
        knownAgentTypes: [],
        selectedList: '',
        selectedListName: '',
        selectedListStatus: 'valid',
        versionMajor: action.versionMajor,
        versionMinor: action.versionMinor,
        isAwake: false,
        retryCount: 0
      }
    }

    case SET_DESCRIPTION_OBJECT: {
      return {
        ...state,
        descriptionObject: action.descriptionObject
      }
    }

    case SET_KNOWN_AGENT_TYPES: {
      return {
        ...state,
        knownAgentTypes: action.knownAgentTypes
      }
    }

    case SET_SELECTED_LIST: {
      window.localStorage.setItem(localStorageKeys.selectedPrimusList, action.selectedList);
      window.localStorage.setItem(localStorageKeys.selectedPrimusListName, action.selectedListName);

      return {
        ...state,
        selectedList: action.selectedList,
        selectedListName: action.selectedListName,
        selectedListStatus: 'valid',
        retryCount: 0
      }
    }

    case SET_SELECTED_LIST_STATUS: {
      return {
        ...state,
        selectedListStatus: action.selectedListStatus
      }
    }

    case SET_OPERATIONS:
      return {
        ...state,
        operations: action.operations
      }

    case SET_IS_AWAKE: {
      window.localStorage.setItem(localStorageKeys.isAwake, String(action.isAwake));

      return {
        ...state,
        isAwake: action.isAwake
      }
    }

    case SET_RETRY_COUNT:
      return {
        ...state,
        retryCount: action.retryCount
      }

    default: {
      return {
        ...state
      }
    }
  }
}

export function PrimusContextProvider({ children }) {
  const [state, dispatch] = useReducer(Reducer, {...initialState}, undefined);

  if (!!state.baseUrl) {
    let api = new PrimusApi(state.baseUrl)

    if (state.versionMajor === -1) {
      api.getClientConfig().then((config) => {
        dispatch({
          type: LOAD_INSTANCE_CONFIG,
          versionMajor: parseInt(config.VERSION.split('.')[1]),
          versionMinor: parseInt(config.VERSION.split('.')[2]),
        });
      });
    }

    // Comment this back in if operations from generated in backend are to be used
    // if (!state.operations?.length) {
    //   api.getOperations().then((res) => {
    //     dispatch({
    //       type: SET_OPERATIONS,
    //       operations: res.operations
    //     })
    //   })
    // }
  }

  return (
    <PrimusStateContext.Provider value={state}>
      <PrimusDispatchContext.Provider value={dispatch}>
        {children}
      </PrimusDispatchContext.Provider>
    </PrimusStateContext.Provider>
  )
}

export const usePrimusDispatch = () => {
  const context = useContext(PrimusDispatchContext);

  if (context === undefined) {
    throw new Error('[PrimusContext] PrimusDispatch must be used within a PrimusContextProvider');
  } else {
    return context;
  }
}

export const usePrimusState = () => {
  const context = useContext(PrimusStateContext);

  if (context === undefined) {
    throw new Error('[PrimusContext] PrimusState must be used within a PrimusContextProvider');
  }

  return context;
}

const LOAD_INSTANCE_CONFIG = 'loadInstanceConfig';

export const SET_DATABASE = 'setBaseUrl';
export const SET_DESCRIPTION_OBJECT = 'setDescriptionObject';
export const SET_KNOWN_AGENT_TYPES ='setKnownAgentTypes';
export const SET_SELECTED_LIST = 'setSelectedList';
export const SET_SELECTED_LIST_STATUS = 'setSelectedListStatus';
export const SET_OPERATIONS = 'setOperations';
export const SET_IS_AWAKE = 'setHealthCheck';
export const SET_RETRY_COUNT = 'setRetryCount';
